@use "../../lib/variables" as var

.color__container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  background-color: var.$background-color

  > div.color__item
    min-width: 4%
    padding: 10px
    font-size: 12px
    text-align: center
    transition: all ease 0.3s

    > p
      padding: 0
      margin: 0
