@use "../../lib/variables" as var

.wrapper
  display: flex
  flex-direction: row
  flex-wrap: wrap
  padding: 1em
  background-color: var.$background-color
  div.firstSortKey
    color: white
    margin: .1em .5em
    p
      margin: 0 0 .2em 0
      &.description
        font-size: smaller
  div.secondSortKey
    color: white
    margin: .1em .5em
    p
      margin: 0 0 .2em 0
    label
      padding: .2em
  button
    padding: .5em
    font-size: large
    min-width: 5em
  input[type=text]
    padding: .5em
    margin: 0 1em
    font-size: large
